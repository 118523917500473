import { CommonModule } from '@angular/common';
import { NgModule, ViewEncapsulation } from '@angular/core';

import { MatDialogModule, MatButtonModule } from '@angular/material';
import { ConfirmDialogService } from './confirm-dialogue.service';
import { ConfirmDialogComponent } from './confirm-dialogue.component';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule
    ],
    declarations: [
        ConfirmDialogComponent,
    ],
    exports: [ConfirmDialogComponent],
    entryComponents: [ConfirmDialogComponent],
  
    providers: [ConfirmDialogService]
  })
  export class ConfirmDialogModule {
  }