import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AwsCognitoService } from "src/app/services/aws-cognito/aws-cognito.service";
import { environment } from "src/environments/environment";

var misc: any = {
  sidebar_mini_active: true
}

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/app/dashboard",
    title: "dashboard",
    type: "link",
    icontype: "business_chart-pie-36"
  },
  // {
  //   path: "/app/audit-requests",
  //   title: "Audit Trail",
  //   type: "link",
  //   icontype: "ui-2_settings-90"
  // },
  {
    path: "/app",
    title: "audit trail",
    type: "sub",
    icontype: "ui-2_settings-90",
    collapse: "audittrail",
    isCollapsed: true,
    children: [
      { path: "audit-requests", title: "Certification", ab: "ACE" },
      { path: "audit-consulting-requests-list", title: "Consulting", ab: "CON" },
    ]
  },
  // {
  //   path: "/app/audits",
  //   title: "Audits-Old",
  //   type: "link",
  //   icontype: "media-2_sound-wave"
  // },

  // {
  //   path: "/app/message-board",
  //   title: "message board",
  //   type: "sub",
  //   icontype: "education_paper",
  //   collapse: "message-board",
  //   isCollapsed: true,
  //   children: [
  //     { path: "message-board-landing", title: "send messages", ab: "AM" },
  //   ]
  // },
  {
    path: "/app/service-requests",
    title: "service requests",
    type: "sub",
    icontype: "ui-2_settings-90",
    collapse: "servicerequests",
    isCollapsed: true,

    children: [
      // { path: "consulting-requests", title: "Consulting Requests", ab: "CSR" },
      { path: "certification-requests", title: "certification requests", ab: "CER" },
      { path: "consulting-request-list",title: "consulting requests", ab: "CRL"}
    ]
  },
  {
    path: "/app/payment-requests",
    title: "payment requests",
    type: "sub",
    icontype: "shopping_credit-card",
    collapse: "paymentrequests",
    isCollapsed: true,

    children: [
      // { path: "payment-offline-requests", title: "Payment Offline Requests", ab: "POR" },
      { path: "offline-payment-list", title: "offline payment list", ab: "OFP" },
      { path: "online-payment-list", title: "online payment history", ab: "ONP" },
      { path: "ach-online-payment-list", title: "ach payment history", ab: "ACH" },
      // { path: "payment-request-list",title: "Payment List", ab: "CRL"}
    ]
  },
  // {
  //   path: "/app/consulting-requests",
  //   title: "consulting-requests",
  //   type: "link",
  //   icontype: "media-2_sound-wave"
  // },
  // {
  //   path: "/app/audit",
  //   title: "Audit",
  //   type: "sub",
  //   icontype: "business_briefcase-24",
  //   collapse: "pages",
  //   isCollapsed: true,
  //   children: [
  //     { path: "", title: "Dashboard", ab: "PP" },
  //     { path: "timeline", title: "Timeline Page", ab: "TP" },
  //     { path: "login", title: "Login Page", ab: "LP" },
  //     { path: "register", title: "Register Page", ab: "RP" },
  //     { path: "lock", title: "Lock Screen Page", ab: "LSP" },
  //     { path: "user", title: "User Page", ab: "UP" }
  //   ]
  // },
  // {
  //   path: "/app/customer-list",
  //   title: "Customers List",
  //   type: "link",
  //   icontype: "education_paper"
  // },
  {
    path: "/app",
    title: "customers list",
    type: "sub",
    icontype: "education_paper",
    collapse: "customerslist",
    isCollapsed: true,
    children: [
      { path: "company-list", title: "company", ab: "COM" },
      { path: "individual-list", title: "individuals", ab: "IND" },
      { path: "suppliers-list", title: "suppliers", ab: "SUP" }
    ]
  },
  {
    path: "/app/administration",
    title: "administration",
    type: "sub",
    icontype: "ui-1_settings-gear-63",
    collapse: "administration",
    isCollapsed: true,
    children: [
      // { path: "audit-categories", title: "audit categories", ab: "AC" },
      { path: "coupon-codes", title: "coupon codes", ab: "CCS" },
      { path: "forced-labour", title: "screening data", ab: "FL" },
      { path: "master-screening", title: "master data", ab: "MD" } ,
      { path: "service-plans", title: "service plans", ab: "CS" },
      { path: "pre-questionnaires", title: "pre-questionnaires", ab: "PQ" },
      { path: "industry-types", title: "industry types", ab: "IT" },
      { path: "upload-reports", title: "upload reports", ab: "UR" } ,
      { path: "message-board", title: "send messages", ab: "MB" } ,
      { path: "message-board-templates", title: "message templates", ab: "MT" } ,
      { path: "custom-training", title: "Custom Training Bulk Upload", ab: "BU" } ,
    ]
  },

  // {
  //   path: "/pages",
  //   title: "Pages",
  //   type: "sub",
  //   icontype: "design_image",
  //   collapse: "pages",
  //   isCollapsed: true,
  //   children: [
  //     { path: "pricing", title: "Pricing Page", ab: "PP" },
  //     { path: "timeline", title: "Timeline Page", ab: "TP" },
  //     { path: "login", title: "Login Page", ab: "LP" },
  //     { path: "register", title: "Register Page", ab: "RP" },
  //     { path: "lock", title: "Lock Screen Page", ab: "LSP" },
  //     { path: "user", title: "User Page", ab: "UP" }
  //   ]
  // },
  // {
  //   path: "/components",
  //   title: "Components",
  //   type: "sub",
  //   icontype: "education_atom",
  //   collapse: "components",
  //   isCollapsed: true,
  //   children: [
  //     { path: "buttons", title: "Buttons", ab: "B" },
  //     { path: "grid", title: "Grid System", ab: "GS" },
  //     { path: "panels", title: "Panels", ab: "P" },
  //     { path: "sweet-alert", title: "Sweet Alert", ab: "SA" },
  //     { path: "notifications", title: "Notifications", ab: "N" },
  //     { path: "icons", title: "Icons", ab: "I" },
  //     { path: "typography", title: "Typography", ab: "T" }
  //   ]
  // },
  // {
  //   path: "/app/reference-data",
  //   title: "reference data",
  //   type: "sub",
  //   icontype: "files_box",
  //   collapse: "reference-data",
  //   isCollapsed: true,
  //   children: [
  //     { path: "human-rights-scores", title: "Human Rights Scores", ab: "HRS" },
  //     // { path: "pre-questionnaires", title: "Pre-Questionnaires", ab: "PQ" },
  //   ]
  // },
  // {
  //   path: "/forms",
  //   title: "Forms",
  //   type: "sub",
  //   icontype: "files_single-copy-04",
  //   collapse: "forms",
  //   isCollapsed: true,
  //   children: [
  //     { path: "regular", title: "Regular Forms", ab: "RF" },
  //     { path: "extended", title: "Extended Forms", ab: "EF" },
  //     { path: "validation", title: "Validation Forms", ab: "VF" },
  //     { path: "wizard", title: "Wizard", ab: "W" }
  //   ]
  // },
  // {
  //   path: "/tables",
  //   title: "Tables",
  //   type: "sub",
  //   icontype: "design_bullet-list-67",
  //   collapse: "tables",
  //   isCollapsed: true,
  //   children: [
  //     { path: "regular", title: "Regular Tables", ab: "RT" },
  //     { path: "extended", title: "Extended Tables", ab: "ET" },
  //     { path: "ngx-datatable", title: "Ngx Datatable", ab: "ND" }
  //   ]
  // },
  // {
  //   path: "/maps",
  //   title: "Maps",
  //   type: "sub",
  //   icontype: "location_pin",
  //   collapse: "maps",
  //   isCollapsed: true,
  //   children: [
  //     { path: "google", title: "Google Maps", ab: "GM" },
  //     { path: "fullscreen", title: "Full Screen Map", ab: "FSM" },
  //     { path: "vector", title: "Vector Map", ab: "VM" }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   title: "Widgets",
  //   type: "link",
  //   icontype: "objects_diamond"
  // },
  // {
  //   path: "/charts",
  //   title: "Charts",
  //   type: "link",
  //   icontype: "business_chart-pie-36"
  // },
  // {
  //   path: "/calendar",
  //   title: "Calendar",
  //   type: "link",
  //   icontype: "media-1_album"
  // }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public sideBarMenuItems:any[];
    public authorizedMenuItems: any[];

    public isCollapsed = true;
    userProfile:any;

    auditorRole:boolean = false;
    salesRole:boolean = false;
    adminRole:boolean = false;
    financeRole:boolean = false;
    screeningRole:boolean = false;
    constructor(private toastr: ToastrService,private awsCognitoService:AwsCognitoService,)
    {

    }

    ngOnInit()
    {
      this.userProfile =  this.awsCognitoService.getAccessToken().payload;
      this.assignPermissions(this.userProfile);
      this.sideBarMenuItems = ROUTES.filter(menuItem => menuItem);
      this.applyMenuAuthorization();
    }

    assignPermissions(userProfile:any)
    {
      this.checkAuditorAuthorization();
      this.checkSalesAuthorization();
      this.checkScreeningAuthorization();
      this.checkAdminAuthorization();
      this.checkFinanceAuthorization();
    }

    checkAuditorAuthorization()
    {
      let auditorPermissionRoles = environment.AUDITOR_ROLES.split(',');
      if(auditorPermissionRoles != undefined && auditorPermissionRoles != null)
      {
        this.auditorRole = auditorPermissionRoles.some(role => this.userProfile['cognito:groups'].includes(role));
      }
    }

    checkSalesAuthorization()
    {
      let salesPermissionRoles = environment.SALES_ROLES.split(',');
      if(salesPermissionRoles != undefined && salesPermissionRoles != null)
      {
        this.salesRole = salesPermissionRoles.some(role => this.userProfile['cognito:groups'].includes(role));
      }
    }

    checkAdminAuthorization()
    {
      let adminPermissionRoles = environment.ADMIN_ROLES.split(',');
      if(adminPermissionRoles != undefined && adminPermissionRoles != null)
      {
        this.adminRole = adminPermissionRoles.some(role => this.userProfile['cognito:groups'].includes(role));
      }
    }

    checkScreeningAuthorization()
    {
      let screeningPermissionRoles = environment.SCREENING_DATA_ROLES.split(',');
      if(screeningPermissionRoles != undefined && screeningPermissionRoles != null)
      {
        this.screeningRole = screeningPermissionRoles.some(role => this.userProfile['cognito:groups'].includes(role));
      }
    }

    checkFinanceAuthorization()
    {
      let financePermissionRoles = environment.FINANCE_ROLES.split(',');
      if(financePermissionRoles != undefined && financePermissionRoles != null)
      {
        this.financeRole = financePermissionRoles.some(role => this.userProfile['cognito:groups'].includes(role));
      }

    }

    applyMenuAuthorization()
    {
      this.authorizedMenuItems = this.sideBarMenuItems ;
      this.authorizedMenuItems.forEach(menuItem => {
          switch(menuItem.title)
          {
            case "dashboard" : {
                                  if(!this.adminRole)
                                      this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "dashboard");
                                  break;
                               }
            case "audit trail" : {
                                    if(!this.auditorRole && !this.adminRole)
                                    {
                                      this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "audit trail");
                                    }
                                    break;
                               }
            case "message board": {
                                      if(!this.adminRole)
                                      this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "message board");
                                      break;
                                   }
            case "service requests": {
                                          if(!this.salesRole && !this.adminRole)
                                          this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "service requests");
                                          break;

                                    }
            case "payment requests" : {
                                  if(!this.financeRole && !this.adminRole)
                                      this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "payment requests");
                                  break;
                               }
            case "customers list" : {    if(!this.adminRole)
                                      this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "customers list");
                                  break;
                               }
            case "administration" : {
                                if(!this.adminRole)
                                {
                                  if(this.screeningRole)
                                  {
                                    let administrationMenuItem = this.sideBarMenuItems.find(menuItem => menuItem.title == "administration")
                                    let administrationChildrenItem = administrationMenuItem.children.filter(item =>item.path == "forced-labour")
                                    this.sideBarMenuItems.forEach(menuItem => {
                                      if(menuItem.title == "administration")
                                      {
                                        menuItem.children = administrationChildrenItem;
                                      }
                                    });
                                  }
                                  else
                                  {
                                    this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "administration");
                                  }
                                }
                                break;
                               }
            case "reference data" : {
                                if(!this.adminRole)
                                    this.sideBarMenuItems = this.sideBarMenuItems.filter(menuItem => menuItem.title != "reference data");
                                break;
                               }

            default: {

                        break;
                     }
          }

           });

          this.menuItems =this.sideBarMenuItems;
    }
    sleep(milliseconds) {
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
        if (new Date().getTime() - start > milliseconds) {
          break;
        }
      }
    }
    myFunc(event, menuitem) {
      event.preventDefault();
      event.stopPropagation();
      this.sleep(10);
      if (menuitem.isCollapsing === undefined) {
        menuitem.isCollapsing = true;

        // menuitem.isCollapsed = !menuitem.isCollapsed;

        var element = event.target;
        while (
          element.getAttribute("data-toggle") != "collapse" &&
          element != document.getElementsByTagName("html")[0]
        ) {
          element = element.parentNode;
        }
        element = element.parentNode.children[1];

        if (
          element.classList.contains("collapse") &&
          !element.classList.contains("show")
        ) {
          element.classList = "before-collapsing";
          var style = element.scrollHeight;

          element.classList = "collapsing";
          setTimeout(function () {
            element.setAttribute("style", "height:" + style + "px");
          }, 1);
          setTimeout(function () {
            element.classList = "collapse show";
            element.removeAttribute("style");
            menuitem.isCollapsing = undefined;
          }, 350);
        } else {
          var style = element.scrollHeight;
          setTimeout(function () {
            element.setAttribute("style", "height:" + (style + 20) + "px");
          }, 3);
          setTimeout(function () {
            element.classList = "collapsing";
          }, 3);
          setTimeout(function () {
            element.removeAttribute("style");
          }, 20);
          setTimeout(function () {
            element.classList = "collapse";
            menuitem.isCollapsing = undefined;
          }, 400);
        }
      }
    }
    minimizeSidebar() {
      const body = document.getElementsByTagName('body')[0];
      if (body.classList.contains('sidebar-mini')) {
        misc.sidebar_mini_active = true
      } else {
        misc.sidebar_mini_active = false;
      }
      if (misc.sidebar_mini_active === true) {
        body.classList.remove('sidebar-mini');
        misc.sidebar_mini_active = false;
        this.showSidebarMessage('Sidebar mini deactivated...');
      } else {
        body.classList.add('sidebar-mini');
        this.showSidebarMessage('Sidebar mini activated...');
        misc.sidebar_mini_active = true;
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function () {
        window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function () {
        clearInterval(simulateWindowResize);
      }, 1000);
    }

    showSidebarMessage(message) {
      this.toastr.show(
        '<span class="now-ui-icons ui-1_bell-53"></span>', message,
        {
          timeOut: 4000,
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-danger alert-with-icon",
          positionClass: "toast-top-right"
        }
      );
    }

}
