import { ChangeDetectionStrategy, Component, HostListener, Inject, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
changeDetection: ChangeDetectionStrategy.OnPush,
selector: 'app-confirm-dialogue',
templateUrl: './confirm-dialogue.component.html',
styles: ['./confirm-dialogue.component.scss']  
})
export class ConfirmDialogComponent 
{
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                  cancelText: string,
                  confirmText: string,
                  message: string,
                  title: string
              }, private mdDialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  public cancel() 
  {
    this.close(false);
  }

  public close(value) 
  {
    this.mdDialogRef.close(value);
  }

  public confirm() 
  {
    this.close(true);
  }

  @HostListener("keydown.esc") 
  public onEsc() 
  {
    this.close(false);
  }

}