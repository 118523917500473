

import { HttpClientModule, HttpClient, HttpHandler } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AwsCognitoService, CognitoCallback } from './services/aws-cognito/aws-cognito.service';
import { AwsCognitoClientService } from './services/aws-cognito-client-portal/aws-cognito-client-portal.service';
import { ApplicationHttpClient, applicationHttpClientCreator } from "./helpers/application-httpclient/application-httpclient";
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmDialogService } from './pages/shared/confirm-dialogue/confirm-dialogue.service';
import { ConfirmDialogModule } from './pages/shared/confirm-dialogue/confirm-dialogue.module';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { MatDialogModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    AuthLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxDropzoneModule,
    PdfViewerModule,
    MatDialogModule,
    ConfirmDialogModule
  ],
  providers: [
    AwsCognitoService,
    AwsCognitoClientService,
    DatePipe,
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpHandler, AwsCognitoService, AwsCognitoClientService]
    },
    {
      provide: HttpClient,
      useClass: ApplicationHttpClient
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
