// Reference: https://github.com/bbogdanov/ExtendAngularHttpClient/blob/master/extendHttpClient/src/app/http-client.ts
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AwsCognitoService } from '../../services/aws-cognito/aws-cognito.service';

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

export function applicationHttpClientCreator(httpHandler: HttpHandler, awsCognitoService: AwsCognitoService) {
    return new ApplicationHttpClient(httpHandler, awsCognitoService);
}

@Injectable()
export class ApplicationHttpClient extends HttpClient {

    private api = environment.api_root;

    constructor(
        private httpHandler: HttpHandler,
        private awsCognitoService: AwsCognitoService) {
        super(httpHandler);
    }

    private generateHttpHeaders(token: string, options?: IRequestOptions) {
        let headers: HttpHeaders = new HttpHeaders();
        if (options && options.headers) {
            headers = options.headers;
        }
        if (!headers.has('Content-Type')) { // Addding Default Content-Type as JSON
            headers = headers.append('Content-Type', 'application/json');
        } else if (headers.get('Content-Type') === 'undefined') { // Removes if Content-Type is 'undefined'
            headers = headers.delete('Content-Type');
        }
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Pragma', 'no-cache');
        headers = headers.append('Expires', '0');
        headers = headers.append('If-Modified-Since', '0');
        headers = headers.append('Authorization', `Bearer ${token}`);
        return {
            headers: headers
        };
    }

    /**
     * GET request
     * @param {string} endPoint it doesn't need / in front of the end point
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @param {string} api use if there is needed to send request to different back-end than the default one.
     * @returns {Observable<T>}
     */
    public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.awsCognitoService.getToken().pipe(
            flatMap(token => {
                return this.get<T>(this.api + endPoint, this.generateHttpHeaders(token, options)).pipe(
                    map((res: any) => {
                        return res;
                        // return <T>res.map(item => {
                        //     console.log(item);
                        //     return item;
                        // });
                    })
                );
            })
        );
    }

    /**
     * POST request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public Post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
        return this.awsCognitoService.getToken().pipe(
            flatMap(token => {
                return this.post<T>(this.api + endPoint, params, this.generateHttpHeaders(token, options)).pipe(
                    map((res: any) => { return res; })
                );
            })
        );
    }

    /**
     * Patch request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public Patch<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
        // return this.post<T>(this.api + endPoint, params, options);
        return this.awsCognitoService.getToken().pipe(
            flatMap(token => {
                return this.patch<T>(this.api + endPoint, params, this.generateHttpHeaders(token, options)).pipe(
                    map((res: any) => { return res; })
                );
            })
        );
    }
}
