import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './helpers/auth-guard/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'app',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/audit/audit.module').then(m => m.AuditModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/audit/audit-requests/audit-requests.module').then(m => m.AuditRequestsModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/message-board/message-board.module').then(m => m.MessageBoardModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/service-requests/service-requests.module').then(m => m.ServiceRequestsModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/payment-requests/payment-request-list/payment-request-list.module').then(m => m.PaymentRequestListModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/customers/customer-list.module').then(m => m.CustomersListModule)
      },
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
exports: [RouterModule]
})
export class AppRoutingModule { }
