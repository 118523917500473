import { Injectable } from '@angular/core';
import { AwsCognitoService, CognitoCallback } from '../aws-cognito/aws-cognito.service';

import * as AWS from 'aws-sdk';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

import { AuthenticationUser } from '../../models/authentication-user/authentication-user';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private awsCognitoService: AwsCognitoService
    ) { }

  login(authenticationUser: AuthenticationUser, callback: CognitoCallback) {
    console.log("UserLoginService: starting the authentication");
    let authenticationData = {
      Username: authenticationUser.email,
      Password: authenticationUser.password,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: authenticationUser.email,
      Pool: this.awsCognitoService.getUserPool()
    };
    // console.log("UserLoginService: Params set...Authenticating the user");
    let cognitoUser = new CognitoUser(userData);
    // console.log("UserLoginService: config is " + AWS.config);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback(`User needs to set password.`, null),
      onSuccess: result => this.onLoginSuccess(callback, result),
      onFailure: err => this.onLoginError(callback, err),
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
          cognitoUser.sendMFACode(confirmationCode, {
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
          });
        });
      }
    });
  }

  logout() {
    console.log("AuthenticationService: Logging out");
    let user = this.awsCognitoService.getCurrentUser();
    if (user) {
      user.signOut();
    }
  }

  private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {

    // console.log("In authenticateUser onSuccess callback");

    AWS.config.credentials = this.awsCognitoService.buildCognitoCreds(session.getIdToken().getJwtToken());

    // console.log("Access Token");
    // console.log(session.getAccessToken().getJwtToken());

    // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
    // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
    // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
    // security credentials. The identity is then injected directly into the credentials object.
    // If the first SDK call we make wants to use our IdentityID, we have a
    // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
    // very innocuous API call that forces this behavior.
    let clientParams: any = {};
    if (environment.sts_endpoint) {
      clientParams.endpoint = environment.sts_endpoint;
    }

    let sts = new AWS.STS(clientParams);
    sts.getCallerIdentity(function (err, data) {
      console.log("UserLoginService: Successfully set the AWS credentials");
      callback.cognitoCallback(null, session);
    });
  }

  private onLoginError = (callback: CognitoCallback, err) => {
    callback.cognitoCallback(err.message, null);
  }

}
