// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  region: 'us-west-2',

  identityPoolId: 'us-west-2:1b365545-fa26-4413-b6ab-1e258869d99b',
  userPoolId: 'us-west-2_ZbGjG26VJ',
  clientId: '4mnetilje46vvdefdkl3lb054q',

  //for add user in client portal
  clIdentityPoolId: 'us-west-2:b3d5c9b7-f251-4f96-868d-f7f30f013183',
  clUserPoolId: 'us-west-2_80ivfNyDF',
  clClientId: '6pvkfof7lqvqodgjgfd8s7ge2l',

  ForcedLabourPlanTypeScreenCount_Professional : "100",
  ForcedLabourPlanTypeScreenCount_Enterprise : "300",
  ForcedLabourPlanTypeScreenCount_Premium : "1000",
  ForcedLabourPlanTypeScreenCount_PremiumPlus : "Unlimited",

  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: 'https://sts.us-west-2.amazonaws.com',

  aws_cognito_oauth_login: 'https://admin-thefreedomseal.auth.us-west-2.amazoncognito.com/login?response_type=code&redirect_uri=https%3A%2F%2Fadmin.thefreedomseal.com%2F&scope=openid%20profile%20aws.cognito.signin.user.admin&client_id=4mnetilje46vvdefdkl3lb054q',
  //api_root: 'https://fs-admin-api.dev.troniefoundation.org'
  api_root: 'https://api.thefreedomseal.com/admin',

  file_format : [".pdf",".jpg",".png",".docx"],
  file_size : "5MB",
  commonDateFormat:'MM/dd/yyyy',
  AUDITOR_ROLES: "FS-AUDIT-ADMIN",
  SALES_ROLES: "FS-SALES",
  ADMIN_ROLES: "FS-GLOBAL-ADMIN",
  FINANCE_ROLES: "FS-FINANCE",
  SCREENING_DATA_ROLES:"FS-SCREENING-DATA",
  reports_endpoint:"https://oj1f6j9adk.execute-api.us-west-2.amazonaws.com/dev/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
