import { Injectable } from '@angular/core';

import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import { CognitoUserPool } from "amazon-cognito-identity-js";

import { environment } from '../../../environments/environment';
import { CognitoIdentity } from 'aws-sdk';
import { Observable } from 'rxjs';


export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

export class IdTokenCallback implements Callback {

  _valueReturnFunction: any = null;

  constructor(valueReturnFunction: any) {
    this._valueReturnFunction = valueReturnFunction;
  }

  callback() {
  }

  callbackWithParam(result) {
    this._valueReturnFunction(result);
  }
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;

  CODE_DELIVERY_DESTINATION: string;
}

@Injectable({
  providedIn: 'root'
})
export class AwsCognitoService {

  public static _REGION = environment.region;

  public static _IDENTITY_POOL_ID = environment.identityPoolId;
  public static _USER_POOL_ID = environment.userPoolId;
  public static _CLIENT_ID = environment.clientId;

  public static _POOL_DATA: any = {
    UserPoolId: AwsCognitoService._USER_POOL_ID,
    ClientId: AwsCognitoService._CLIENT_ID
  };

  public cognitoCreds: AWS.CognitoIdentityCredentials;

  constructor() { }

  getUserPool() {
    if (environment.cognito_idp_endpoint) {
      AwsCognitoService._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
    }
    return new CognitoUserPool(AwsCognitoService._POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
    
  }

  getAccessToken() 
  {
    var loggedInUserData = null;
    if (this.getCurrentUser() != null)
       this.getCurrentUser().getSession(function (err, session) 
       {
          if (err) 
          {
            console.log(err);
            // console.log("CognitoUtil: Can't set the credentials:" + err);        
          }
          else
          {
              if (session.isValid()) 
              {
                loggedInUserData = session.getIdToken();
              } 
              else 
              {
                // console.log("CognitoUtil: Got the id token, but the session isn't valid");
              }
          }
       });

       return loggedInUserData;
  }   

  async forceTokenRefresh(refresh_token:any)
   {     
      await this.getCurrentUser().refreshSession(refresh_token, (err, session) => {
      if (err) {
        console.log(err);
      }
      else {
        return this.buildCognitoCredsAfterRefresh(session.getIdToken().getJwtToken());
      }
    });           
   }   
 
   buildCognitoCredsAfterRefresh(idTokenJwt: string) 
   {
     let url = 'cognito-idp.' + AwsCognitoService._REGION.toLowerCase() + '.amazonaws.com/' + AwsCognitoService._USER_POOL_ID;
     if (environment.cognito_idp_endpoint) {
       url = environment.cognito_idp_endpoint + '/' + AwsCognitoService._USER_POOL_ID;
     }
     let logins: CognitoIdentity.LoginsMap = {};
     logins[url] = idTokenJwt;
     let params = {
       IdentityPoolId: AwsCognitoService._IDENTITY_POOL_ID, /* required */
       Logins: logins
     };
     let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
     if (environment.cognito_identity_endpoint) {
       serviceConfigs.endpoint = environment.cognito_identity_endpoint;
     }
     let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
     this.setCognitoCreds(creds); 
 
     ( < AWS.CognitoIdentityCredentials > AWS.config.credentials).refresh((error) => {
 
     });
    
   }

   getRefreshToken() 
   {
     var loggedInUserSession = null;
     if (this.getCurrentUser() != null)
        this.getCurrentUser().getSession(function (err, session) 
        {
           if (err) 
           {
             console.log(err);        
           }
           else
           {
               if (session.isValid()) 
               {
                loggedInUserSession = session.getRefreshToken();                
               } 
               else 
               {
                //  console.log("CognitoUtil: Got the id token, but the session isn't valid");
               }
           }
        });
 
        return loggedInUserSession;
   } 

  getIdToken(callback: Callback): void {
    if (callback == null) {
      throw ("CognitoUtil: callback in getIdToken is null...returning");
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            console.log("CognitoUtil: Got the id token, but the session isn't valid");
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  getToken(): Observable<any> {
    return Observable.create(observer => {
      this.getIdToken(new IdTokenCallback(result => { observer.next(result); }));
    });
  }

  buildCognitoCreds(idTokenJwt: string) {
    let url = 'cognito-idp.' + AwsCognitoService._REGION.toLowerCase() + '.amazonaws.com/' + AwsCognitoService._USER_POOL_ID;
    if (environment.cognito_idp_endpoint) {
      url = environment.cognito_idp_endpoint + '/' + AwsCognitoService._USER_POOL_ID;
    }
    let logins: CognitoIdentity.LoginsMap = {};
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: AwsCognitoService._IDENTITY_POOL_ID, /* required */
      Logins: logins
    };
    let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
    if (environment.cognito_identity_endpoint) {
      serviceConfigs.endpoint = environment.cognito_identity_endpoint;
    }
    let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);
    return creds;
  }

  setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
  }

  getParameters(callback: Callback) {
    let cognitoUser = this.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err)
          console.log(err);
        else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              callback.callbackWithParam(result);
            }
          });
        }

      });
    } else {
      callback.callbackWithParam(null);
    }
  }

  getGroupsForUser()
  {
    //TODO:: Call this api  "ListGroupsForUser()"
    //The above method is to get the list of groups by userid
    //From AWS IAM service
  }
  
}
